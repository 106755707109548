<template>
  <div>
    <div class="error white--text pa-2 font-italic" v-if="errMsg">
      {{ errMsg }}
    </div>

    <template v-else>
      <div v-if="isWait">Đang xử lý dữ liệu</div>
      <div v-else>
        <v-simple-table class="table-padding-2 table-h-36 mb-8">
          <template v-slot:default>
            <tbody>
              <tr class="text-center font-weight-medium">
                <td>Phân loại</td>
                <td>{{ $t("labels.quantity") }}</td>
                <td>Tổng doanh thu</td>
                <td>Doanh thu hàng hóa</td>
                <td>Thu nhập khác</td>
                <td style="width: 160px">Ngày</td>
              </tr>
              <tr class="text-center warning--text font-weight-medium">
                <td class="table-td-border-bottom">Đơn hàng</td>
                <td class="table-td-border-bottom">
                  {{ incomes.length }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumRevenueByOrders(incomes)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumGoodsRevenueByOrders(incomes)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumOtherRevenueByOrders(incomes)) }}
                </td>
                <td class="" rowspan="2">
                  <span class="fs-16px error--text">
                    {{ formatDateTime(summary.data_from, "DD/MM/YYYY") }}
                  </span>
                  <br />
                  <span class="fs-16px error--text">
                    {{ formatDateTime(summary.data_to, "DD/MM/YYYY") }}
                  </span>
                </td>
              </tr>
              <tr class="text-center success--text font-weight-medium">
                <td class="table-td-border-bottom">Xuất hóa đơn</td>
                <td class="table-td-border-bottom">
                  {{ revenueOrders.length }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumRevenueByOrders(revenueOrders)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumGoodsRevenueByOrders(revenueOrders)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumOtherRevenueByOrders(revenueOrders)) }}
                </td>
              </tr>
              <!-- <tr class="text-center error--text font-weight-medium">
                <td class="table-td-border-bottom">Không xuất</td>
                <td class="table-td-border-bottom">
                  {{ noRevenueOrders.length }}
                </td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
              </tr> -->
              <tr class="text-center primary--text font-weight-medium">
                <td class="table-td-border-bottom">Điều chỉnh tự động</td>
                <td class="table-td-border-bottom">
                  {{ autoAdjustOrders.length }}
                </td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom" rowspan="2">
                  <v-btn
                    color="success"
                    @click="invoiceCreate"
                    :disabled="isDisabledBtn"
                  >
                    Xuất hóa đơn
                  </v-btn>
                </td>
              </tr>
              <tr class="text-center purple--text font-weight-medium">
                <td class="table-td-border-bottom">Cần xử lý</td>
                <td class="table-td-border-bottom">
                  {{ waitProcessOrders.length }}
                </td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- <div class="mb-3 primary white--text pa-2">
          <div class="d-flex align-center justify-space-between w-100">
            <span>Danh mục xuất</span>
            <v-btn
              color="error"
              x-small
              v-if="noPriceItems.length > 0"
              @click="downloadNoPriceGoods"
            >
              <v-icon small class="mr-2">mdi-download</v-icon>
              Hàng hóa chưa có giá
            </v-btn>
          </div>
        </div>
        <v-simple-table class="table-padding-2 mb-8 table-h-30">
          <template v-slot:default>
            <tbody>
              <tr class="text-center font-weight-medium">
                <td>TT</td>
                <td>SKU</td>
                <td>{{ $t("labels.barcode") }}</td>
                <td>Tên</td>
                <td>Đặc tả</td>
                <td>Size</td>
                <td>Thực xuất</td>
                <td>Giá bán lẻ</td>
                <td class="success--text">
                  Thành tiền <br />
                  {{ formatNumber(summary.total_base_price) }}
                </td>
                <td class="success--text">
                  Số tiền chiết khấu <br />
                  {{ formatNumber(summary.total_discount_price) }}
                </td>
                <td class="success--text">
                  Giá trị hàng hóa <br />
                  {{ formatNumber(summary.total_goods_revenue) }}
                </td>
              </tr>
              <tr
                class="text-center"
                v-for="(item, index) in groupItems"
                :key="`g_${index}_${item.id}`"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.customer_goods_barcode }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.size }}</td>
                <td>{{ item.total_quantity }}</td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null ? formatNumber(item.price) : "CHƯA CÓ"
                  }}
                </td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null
                      ? formatNumber(item.base_price)
                      : "CHƯA CÓ"
                  }}
                </td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null
                      ? formatNumber(item.discount_price)
                      : "CHƯA CÓ"
                  }}
                </td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null
                      ? formatNumber(item.bill_invoice)
                      : "CHƯA CÓ"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->
      </div>
    </template>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";
import moment from "moment";

export default {
  name: "Lazada",
  components: {},
  props: {
    file: {},
    source: {
      type: Number,
      default: () => 0,
    },
    idShop: {
      type: Number,
      default: () => 0,
    },
    typeCreateExport: {
      type: Number,
      default: () => 0,
    },
    typeProductOrder: {
      type: Number,
      default: () => 0,
    },
    idCustomerInvoiceContract: {
      type: Number,
      default: () => 0,
    },
    idCustomerInvoiceContractTemplate: {
      type: Number,
      default: () => 0,
    },
    allowDate: {
      type: String,
      default: () => "2024-01-01",
    },
  },
  data: () => ({
    summary: {},
    incomes: [],
    adjustments: [],
    waitProcessOrders: [],
    autoRequestOrders: [],
    incomeItems: [],
    autoAdjusts: {},
    errMsg: null,
    filePath: null,
    isWait: true,
  }),
  computed: {
    noPriceItems() {
      if (!this.incomeItems || this.incomeItems.length === 0) {
        return [];
      }
      return [...this.incomeItems].filter((item) => item.price == null);
    },
    revenueOrders() {
      return [...this.autoRequestOrders].filter(
        (io) => !io.id_customer_invoice_order
      );
    },
    noRevenueOrders() {
      return [...this.autoRequestOrders].filter((io) => io.revenue == 0);
    },
    autoAdjustOrders() {
      const orderProcessed = [...this.autoRequestOrders].filter(
        (io) => io.id_customer_invoice_order
      );
      const orderWait = [...this.waitProcessOrders].filter(
        (io) => io.id_customer_invoice_order
      );
      return [...orderProcessed, ...orderWait];
    },
    isDisabledBtn() {
      return this.noPriceItems.length > 0;
    },
    groupItems() {
      const groups = {};
      for (let i = 0; i < this.incomeItems.length; i++) {
        const item = this.incomeItems[i];
        if (item.revenue == 0) {
          continue;
        }
        if (!groups[item.id]) {
          groups[item.id] = {
            id: item.id,
            price: item.price,
            sku: item.sku,
            customer_goods_barcode: item.customer_goods_barcode,
            name: item.name,
            description: item.description,
            size: item.size,
            total_quantity: +item.total_quantity,
            revenue: +item.revenue,
            base_price: +item.base_price,
            discount_price: +item.discount_price,
            bill_invoice: +item.bill_invoice,
          };
        } else {
          groups[item.id].total_quantity += +item.total_quantity;
          groups[item.id].revenue += +item.revenue;
          groups[item.id].base_price += +item.base_price;
          groups[item.id].discount_price += +item.discount_price;
          groups[item.id].bill_invoice += +item.bill_invoice;
        }
      }
      return Object.values(groups);
    },
  },
  watch: {},
  mounted() {
    this.readFile();
  },
  methods: {
    async readFile() {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });

        const summary = {
          data_from: 0,
          data_to: 0,
          base_price: 0,
          seller_subsidizes_price: 0,
          refund_to_buyer: 0,
          e_market_subsidizes_price: 0,
          discount_price: 0,
          seller_return_coin: 0,
          total_revenue: 0,
          total_order_revenue: 0,
        };

        let worksheetIncome = workbook.Sheets["Income Overview"];
        const { incomes, data_from, data_to } =
          this.getIncomeOrders(worksheetIncome);

        this.isWait = false;
        summary.data_from = data_from;
        summary.data_to = data_to;
        this.processData({ summary, incomes, adjustments: [] });
      };
      await reader.readAsArrayBuffer(this.file);
    },

    async processData({ summary, incomes, adjustments }) {
      this.errMsg = null;

      if (this.allowDate && summary.data_from < this.allowDate) {
        this.errMsg = `Chỉ cho phép xuất hóa đơn từ ngày ${this.allowDate}`;
        return false;
      }

      const today = moment().format("YYYY-MM-DD");
      if (summary.data_to >= today) {
        this.errMsg = `Danh sách income cần nhỏ hơn ngày hôm ngay`;
        return false;
      }

      const checkIncomes = [...incomes].filter(
        (od) =>
          od.payment_date < summary.data_from ||
          od.payment_date > summary.data_to
      );
      if (checkIncomes.length > 0) {
        const orderIds = [...checkIncomes].map((co) => co.customer_order_id);
        this.errMsg = `Danh sách income, đơn hàng ${orderIds.join(
          "; "
        )} không đúng ngày ${summary.data_from} - ${summary.data_to}`;
        return false;
      }

      const checkAdjustments = [...adjustments].filter(
        (od) =>
          od.adjustment_date < summary.data_from ||
          od.adjustment_date > summary.data_to
      );
      if (checkAdjustments.length > 0) {
        const orderIds = [...checkAdjustments].map(
          (co) => co.customer_order_id
        );
        this.errMsg = `Danh sách adjustment, đơn hàng ${orderIds.join(
          "; "
        )} không đúng ngày ${summary.data_from} - ${summary.data_to}`;
        return false;
      }

      this.summary = { ...summary };
      this.incomes = [...incomes];
      this.adjustments = [...adjustments];

      try {
        const { data } = await httpClient.post("/invoice-income-process", {
          id_customer_e_marketplace_shop: this.idShop,
          source: this.source,
          data_from: summary.data_from,
          data_to: summary.data_to,
          incomes,
          adjustments,
        });

        this.waitProcessOrders = [...data.waitProcessOrders];
        this.autoRequestOrders = [...data.autoRequestOrders];
        this.summary = {
          ...this.summary,
        };

        this.uploadFile();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.errMsg = errMsg;
      }
    },

    getIncomeOrders(worksheetIncome) {
      const mappingFields = {
        "Mã đơn hàng": "customer_order_id",
        "Order Number": "customer_order_id",

        "Tên phí": "fee_type",
        "Fee Name": "fee_type",

        "Ngày tạo đơn": "buy_date",
        "Order Creation Date": "buy_date",

        "Ngày giao dịch": "payment_date",
        "Transaction Date": "payment_date",

        "Số tiền (Đã bao gồm thuế)": "amount",
        "Amount(Include Tax)": "amount",

        "Bình luận": "reason",
        Comment: "reason",
      };

      const incomeKeys = Object.keys(worksheetIncome);
      const startCell = incomeKeys[1];
      const endKey = incomeKeys[incomeKeys.length - 1].includes("margins")
        ? incomeKeys.length - 2
        : incomeKeys.length - 1;
      const endCell = incomeKeys[endKey];
      worksheetIncome["!ref"] = `${startCell}:${endCell}`;
      console.log("income", `${startCell}:${endCell}`);
      const values = utils.sheet_to_json(worksheetIncome);
      console.log(values);

      const arr = [...values]
        .map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            const field = i.trim();
            const val = `${v[field]}`.trim();
            if (["payment_date", "buy_date"].includes(mappingFields[field])) {
              item[mappingFields[field]] = val
                ? moment(val, "DD MMM YYYY").format("YYYY-MM-DD")
                : "";
            } else {
              item[mappingFields[field]] = val;
            }
          });

          return item;
        })
        .filter((item) => item.payment_date >= this.allowDate);

      const groupByOrder = {};
      const feeName = {
        "Giá trị sản phẩm": "goods_revenue",
        "Item Price Credit": "goods_revenue",

        "Phí cố định": "fixed_fee",
        Commission: "fixed_fee",

        "Phí dịch vụ chương trình FreeShip MAX": "freeship_max_fee",
        "Free Shipping Max Fee": "freeship_max_fee",

        "Phí xử lý đơn hàng": "order_process_fee",

        "Campaign Fee": "campaign_fee", // moi

        "Phí khuyến mãi mã giảm giá": "discount_fee",
        "Promotional Charges Vouchers": "discount_fee",

        "Phí khuyến mãi flexi combo": "discount_flexi_combo_fee",
        "Promotional Charges Flexi-Combo": "discount_flexi_combo_fee",

        "Phí vận chuyển tài trợ bởi nhà bán hàng": "delivery_seller_fee",
        "Shipping Fee Subsidy (By Seller)": "delivery_seller_fee",

        "Voucher tích lũy": "voucher_accumulate",
        "Lazada Bonus": "voucher_accumulate",

        "Voucher tích lũy - Lazada tài trợ": "lazada_voucher_accumulate",
        "Lazada Bonus - LZD co-fund": "lazada_voucher_accumulate",

        "Điều chỉnh phí vận chuyển chênh lệch": "adjust_delivery_fee",
        "Wrong Weight Adjustment": "adjust_delivery_fee",

        "Điều chỉnh số dư - Cộng thêm": "adjust_balance",
        "Seller balance adjustments - Credit": "adjust_balance",

        "Hoàn lại giá trị sản phẩm": "adjust_goods_revenue",
        "Reversal Item Price": "adjust_goods_revenue",

        "Hoàn lại phí cố định": "adjust_fixed_fee",
        "Reversal Commission": "adjust_fixed_fee",

        "Hoàn lại phí dịch vụ chương trình FreeShip MAX":
          "adjust_freeship_max_fee",
        "Reversal of Free Shipping Max Fee": "adjust_freeship_max_fee",

        "Hoàn lại phí khuyến mãi mã giảm giá": "adjust_discount_fee",
        "Reversal Promotional Charges Vouchers": "adjust_discount_fee",

        "Hoàn lại phí vận chuyển trả bởi khách hàng":
          "adjust_delivery_customer_fee",
        "Shipping Fee Refund to Customer": "adjust_delivery_customer_fee",

        "Hoàn lại phí vận chuyển tài trợ bởi Lazada":
          "adjust_delivery_lazada_fee",
        "Shipping Fee Voucher Refund to Laz": "adjust_delivery_lazada_fee",
      };
      const feeCodes = {
        goods_revenue: "GR",
        fixed_fee: "FF",
        freeship_max_fee: "FMF",
        order_process_fee: "OPF",
        discount_fee: "DF",
        discount_flexi_combo_fee: "DFCF",
        delivery_seller_fee: "DSF",
        voucher_accumulate: "VA",
        lazada_voucher_accumulate: "LVA",
        adjust_delivery_fee: "ADF",
        adjust_balance: "AB",
        adjust_goods_revenue: "AGR",
        adjust_fixed_fee: "AFF",
        adjust_freeship_max_fee: "AFMF",
        adjust_discount_fee: "ADIF",
        adjust_delivery_customer_fee: "ADCF",
        adjust_delivery_lazada_fee: "ADLF",
        campaign_fee: "CF",
      };

      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const feeKey = feeName[item.fee_type];

        if (!item.customer_order_id) {
          const paymentDateStr = item.payment_date.split("-").join("");
          item.customer_order_id = `${feeCodes[feeKey]}_${paymentDateStr}`;
          item.type = item.fee_type;
        }

        const groupKey = `${item.customer_order_id}_${item.payment_date}`;
        if (!groupByOrder[groupKey]) {
          groupByOrder[groupKey] = {
            customer_order_id: item.customer_order_id,
            buy_date: item.buy_date,
            payment_date: item.payment_date,
            type: item.type || "",
            reason: item.reason || "",
            goods_revenue: 0,
            fixed_fee: 0,
            freeship_max_fee: 0,
            order_process_fee: 0,
            discount_fee: 0,
            discount_flexi_combo_fee: 0,
            delivery_seller_fee: 0,
            voucher_accumulate: 0,
            lazada_voucher_accumulate: 0,
            adjust_delivery_fee: 0,
            adjust_balance: 0,
            adjust_goods_revenue: 0,
            adjust_fixed_fee: 0,
            adjust_freeship_max_fee: 0,
            adjust_discount_fee: 0,
            adjust_delivery_customer_fee: 0,
            adjust_delivery_lazada_fee: 0,
            campaign_fee: 0,
          };
        }

        groupByOrder[groupKey][feeKey] += +item.amount;
      }

      let data_from = null;
      let data_to = null;
      const incomes = Object.values(groupByOrder).map((od) => {
        if (!data_from || data_from > od.payment_date) {
          data_from = od.payment_date;
        }

        if (!data_to || data_to < od.payment_date) {
          data_to = od.payment_date;
        }

        const goods_revenue = +od.goods_revenue;
        let other_revenue = 0;
        let other_revenue_delivery = 0;
        if (od.lazada_voucher_accumulate > 0) {
          other_revenue += od.lazada_voucher_accumulate;
        }
        if (od.adjust_balance > 0) {
          other_revenue += od.adjust_balance;
        }
        const revenue = goods_revenue + other_revenue;
        return {
          ...od,
          goods_revenue,
          other_revenue,
          other_revenue_delivery,
          revenue,
        };
      });

      return {
        incomes,
        data_from,
        data_to,
      };
    },

    getAdjustments(worksheetAdjustment) {
      let adjustments = [];
      if (worksheetAdjustment) {
        const adjustmentMappingFields = {
          "Mã giao dịch": "stt",
          "Adjustment Complete Date": "adjustment_date",
          "Loại điều chỉnh | Mô tả": "type",
          "Adjustment Reason": "reason",
          "Số tiền điều chỉnh": "adjustment_amount",
          "Mã đơn hàng liên quan": "customer_order_id",
        };
        worksheetAdjustment["!ref"] = worksheetAdjustment["!ref"].replace(
          "A1:",
          "A14:"
        );
        const adjustmentValues = utils.sheet_to_json(worksheetAdjustment);
        adjustments = [...adjustmentValues]
          .map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              item[adjustmentMappingFields[i]] = `${v[i]}`.trim();
            });
            return item;
          })
          .filter((item) => item.stt && item.adjustment_date && item.type);
      }
      return adjustments;
    },

    sumRevenueByOrders(orders) {
      let revenue = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        revenue += order.revenue;
      }
      return revenue;
    },

    sumGoodsRevenueByOrders(orders) {
      let revenue = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        revenue += +order.goods_revenue;
      }
      return revenue;
    },

    sumOtherRevenueByOrders(orders) {
      let revenue = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        revenue += +order.other_revenue;
      }
      return revenue;
    },

    async invoiceCreate() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-income-create", {
          source: 2,
          id_customer_invoice_contract: this.idCustomerInvoiceContract,
          id_customer_invoice_contract_template:
            this.idCustomerInvoiceContractTemplate,
          type_create_export: this.typeCreateExport,
          type_product_order: this.typeProductOrder,
          id_customer_e_marketplace_shop: this.idShop,
          data_from: this.summary.data_from,
          data_to: this.summary.data_to,
          incomes: this.incomes,
          adjustments: this.adjustments,
          file: this.filePath,
        });

        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("success");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async uploadFile() {
      let fd = new FormData();
      fd.append("file", this.file);
      const { data } = await httpClient
        .post(`/upload-image`, fd)
        .then()
        .catch();
      this.filePath = data;
    },

    downloadNoPriceGoods() {
      const ids = [];
      for (let i = 0; i < this.noPriceItems.length; i++) {
        const item = this.noPriceItems[i];
        if (!ids.includes(item.id)) {
          ids.push(item.id);
        }
      }
      this.$emit("downloadExcelNoPrice", ids);
    },
  },
};
</script>
